import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'

import styles from "../components/404.module.css"

class RootIndex extends React.Component {
  render() {
    return (
      <Layout location={this.props.location} noTop>
        <div className={styles.section404}>
          <img alt="404 image" src="/images/404.jpg"/>
        </div>
      </Layout>
    )
  }
}

export default RootIndex